import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k630.jpg'
import image_product_mobile from '../../../images/product/k630_mobile.jpg'

class K630Page extends Component {

	render() {
		return (
		  <Layout mobileTitle={true}
		  		customClass='product-title'
		  		activePath='product'
		  		pageTitle='Konstrukt&trade; K-630 Join "N" Seal Hybrid Polyurethane Sealant'>
		  	<ProductPage
		  		pageTitle='Konstrukt&trade; K-630 Join "N" Seal Hybrid Polyurethane Sealant'
		  		productID={"81"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		nocalc={true}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
		  					"key":"",
		  					"name":""
		  				}
		  			]
		  		}
		  		/>
		  </Layout>
		);
	}
}
export default K630Page
